import { useState, useEffect } from 'react';
import { Sun, Battery, Zap, Leaf, ArrowRight, CheckCircle2, Shield, Wind, CloudSun } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from '@react-spring/web';

const solutions = [
  {
    icon: <Sun className="h-8 w-8" />,
    title: "Premium Solar Panels",
    description: "High-efficiency Tier-1 panels with advanced PERC technology",
    features: [
      "25-year performance warranty",
      "Anti-soiling coating",
      "Enhanced low-light performance",
      "Temperature coefficient -0.35%/°C"
    ],
    specs: {
      efficiency: "21.7%",
      power: "550W Peak",
      warranty: "25 Years",
      lifespan: "30+ Years"
    },
    color: "yellow",
    image: "https://images.unsplash.com/photo-1509391366360-2e959784a276?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80"
  },
  {
    icon: <Battery className="h-8 w-8" />,
    title: "Smart Storage",
    description: "Next-gen lithium battery systems with intelligent BMS",
    features: [
      "Advanced BMS technology",
      "Modular scalable design",
      "Remote monitoring",
      "Emergency backup power"
    ],
    specs: {
      capacity: "15 kWh",
      cycles: "10,000+",
      efficiency: "98%",
      backup: "24 Hours"
    },
    color: "blue",
    image: "https://images.unsplash.com/photo-1569012871812-f38ee64cd54c?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80"
  },
  {
    icon: <Wind className="h-8 w-8" />,
    title: "Hybrid Systems",
    description: "Combined solar-wind energy solutions for optimal power",
    features: [
      "Dual power generation",
      "Weather-independent",
      "Smart grid integration",
      "Enhanced reliability"
    ],
    specs: {
      power: "10-50 kW",
      efficiency: "96%",
      backup: "Continuous",
      monitoring: "Real-time"
    },
    color: "green",
    image: "https://images.unsplash.com/photo-1532601224476-15c79f2f7a51?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80"
  },
  {
    icon: <CloudSun className="h-8 w-8" />,
    title: "Smart Grid Solutions",
    description: "Intelligent grid integration with bi-directional power flow",
    features: [
      "Net metering ready",
      "Load management",
      "Peak shaving",
      "Grid stabilization"
    ],
    specs: {
      response: "<10ms",
      efficiency: "99%",
      modes: "Multiple",
      integration: "Universal"
    },
    color: "purple",
    image: "https://images.unsplash.com/photo-1548675692-aa173d8f108c?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80"
  }
];

const getColorClass = (color) => {
  const colors = {
    yellow: "from-yellow-400 to-yellow-600",
    blue: "from-blue-400 to-blue-600",
    green: "from-green-400 to-green-600",
    purple: "from-purple-400 to-purple-600"
  };
  return colors[color];
};

function EnergySolutions() {
  const [selectedSolution, setSelectedSolution] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prev) => (prev + 1) % solutions.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <section id="solutions" className="py-24 bg-gradient-to-br from-gray-900 to-gray-800 relative overflow-hidden">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[url('/grid.svg')] bg-center [mask-image:linear-gradient(180deg,white,rgba(255,255,255,0))]" />
        <motion.div
          animate={{
            scale: [1, 1.2, 1],
            opacity: [0.3, 0.5, 0.3]
          }}
          transition={{
            duration: 8,
            repeat: Infinity,
            ease: "easeInOut"
          }}
          className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-96 h-96 bg-yellow-500/20 rounded-full blur-3xl"
        />
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center"
        >
          <span className="text-yellow-500 font-semibold tracking-wider uppercase">Our Solutions</span>
          <h2 className="mt-2 text-3xl font-bold text-white sm:text-4xl lg:text-5xl">
            Complete Energy Solutions
          </h2>
          <p className="mt-4 text-xl text-gray-300 max-w-2xl mx-auto">
            Discover our range of premium solar and renewable energy solutions
          </p>
        </motion.div>

        <div ref={ref} className="mt-16 grid gap-8 md:grid-cols-2 lg:grid-cols-4">
          {solutions.map((solution, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={inView ? { opacity: 1, y: 0 } : {}}
              transition={{ delay: index * 0.1 }}
              whileHover={{ y: -10 }}
              className={`relative group cursor-pointer transform transition-all duration-500 ${
                activeIndex === index ? 'scale-105' : ''
              }`}
              onClick={() => setSelectedSolution(solution)}
            >
              <div className="absolute inset-0 bg-gradient-to-r from-gray-800 to-gray-700 rounded-2xl transform transition-transform group-hover:scale-105 group-hover:rotate-1" />
              <div className="relative p-8 bg-gray-800/50 backdrop-blur-xl rounded-2xl border border-gray-700 transform transition-transform group-hover:-rotate-1">
                <div className={`w-16 h-16 rounded-xl bg-gradient-to-r ${getColorClass(solution.color)} flex items-center justify-center mb-6 transform transition-transform group-hover:scale-110 group-hover:rotate-6`}>
                  {solution.icon}
                </div>

                <h3 className="text-xl font-bold text-white mb-3">{solution.title}</h3>
                <p className="text-gray-300 mb-6">{solution.description}</p>

                <div className="space-y-2">
                  {solution.features.slice(0, 2).map((feature, idx) => (
                    <div key={idx} className="flex items-center text-sm text-gray-400">
                      <CheckCircle2 className="h-4 w-4 text-yellow-500 mr-2" />
                      {feature}
                    </div>
                  ))}
                </div>

                <motion.button
                  whileHover={{ x: 5 }}
                  className={`mt-6 flex items-center text-sm font-semibold bg-gradient-to-r ${getColorClass(solution.color)} bg-clip-text text-transparent`}
                >
                  Learn More <ArrowRight className="ml-1 h-4 w-4" />
                </motion.button>
              </div>
            </motion.div>
          ))}
        </div>

        <AnimatePresence>
          {selectedSolution && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50"
              onClick={() => setSelectedSolution(null)}
            >
              <motion.div
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                className="bg-white rounded-2xl p-8 max-w-2xl w-full"
                onClick={e => e.stopPropagation()}
              >
                <div className="flex justify-between items-start mb-6">
                  <div className="flex items-center">
                    <div className={`bg-gradient-to-r ${getColorClass(selectedSolution.color)} p-4 rounded-xl text-white`}>
                      {selectedSolution.icon}
                    </div>
                    <div className="ml-4">
                      <h3 className="text-2xl font-bold">{selectedSolution.title}</h3>
                      <p className="text-gray-600">{selectedSolution.description}</p>
                    </div>
                  </div>
                  <button 
                    onClick={() => setSelectedSolution(null)}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    ✕
                  </button>
                </div>

                <div className="grid grid-cols-2 gap-6 mb-6">
                  {Object.entries(selectedSolution.specs).map(([key, value], idx) => (
                    <motion.div
                      key={key}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: idx * 0.1 }}
                      className="bg-gray-50 p-4 rounded-xl"
                    >
                      <p className="text-sm text-gray-600 capitalize">{key}</p>
                      <p className="text-lg font-semibold">{value}</p>
                    </motion.div>
                  ))}
                </div>

                <div className="space-y-3 mb-6">
                  <h4 className="font-semibold">Key Features:</h4>
                  {selectedSolution.features.map((feature, idx) => (
                    <motion.div
                      key={idx}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: idx * 0.1 }}
                      className="flex items-center"
                    >
                      <Shield className="h-5 w-5 text-yellow-500 mr-2" />
                      <span>{feature}</span>
                    </motion.div>
                  ))}
                </div>

                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className={`w-full bg-gradient-to-r ${getColorClass(selectedSolution.color)} text-white py-3 rounded-xl font-semibold`}
                >
                  Get Detailed Quote
                </motion.button>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="mt-16 text-center"
        >
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="bg-gradient-to-r from-yellow-500 to-yellow-600 text-white px-8 py-4 rounded-full font-semibold inline-flex items-center group hover:shadow-lg hover:shadow-yellow-500/30 transition-all duration-300"
          >
            View All Solutions
            <ArrowRight className="ml-2 h-5 w-5 transform group-hover:translate-x-1 transition-transform" />
          </motion.button>
        </motion.div>
      </div>
    </section>
  );
}

export default EnergySolutions;