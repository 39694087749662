import React from 'react';
import { motion } from 'framer-motion';
import { TrendingUp, Clock, Shield, Sun, DollarSign } from 'lucide-react';

export default function ROICard({ installationCost, annualSavings, paybackPeriod }) {
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 0
    }).format(amount);
  };

  const twentyFiveYearSavings = annualSavings * 25;
  const roi = ((twentyFiveYearSavings - installationCost) / installationCost) * 100;
  const monthlyEMI = calculateEMI(installationCost, 5, 8.5); // 5 years loan at 8.5% interest

  function calculateEMI(principal, years, interestRate) {
    const monthlyRate = interestRate / 12 / 100;
    const numberOfPayments = years * 12;
    const emi = (principal * monthlyRate * Math.pow(1 + monthlyRate, numberOfPayments)) / 
                (Math.pow(1 + monthlyRate, numberOfPayments) - 1);
    return emi;
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-white/10 backdrop-blur-sm p-6 rounded-xl border border-white/20"
    >
      <h3 className="text-xl font-bold text-white mb-4">Financial Analysis</h3>
      
      <div className="grid grid-cols-2 gap-4">
        <div className="bg-white/5 p-4 rounded-lg">
          <div className="flex items-center mb-2">
            <TrendingUp className="h-5 w-5 text-green-500 mr-2" />
            <span className="text-sm text-gray-300">25 Year Savings</span>
          </div>
          <p className="text-xl font-bold text-white">{formatCurrency(twentyFiveYearSavings)}</p>
          <p className="text-xs text-gray-400 mt-1">Total returns over system lifetime</p>
        </div>

        <div className="bg-white/5 p-4 rounded-lg">
          <div className="flex items-center mb-2">
            <Clock className="h-5 w-5 text-yellow-500 mr-2" />
            <span className="text-sm text-gray-300">Payback Period</span>
          </div>
          <p className="text-xl font-bold text-white">{paybackPeriod} Years</p>
          <p className="text-xs text-gray-400 mt-1">Time to recover investment</p>
        </div>

        <div className="bg-white/5 p-4 rounded-lg">
          <div className="flex items-center mb-2">
            <Shield className="h-5 w-5 text-blue-500 mr-2" />
            <span className="text-sm text-gray-300">ROI</span>
          </div>
          <p className="text-xl font-bold text-white">{roi.toFixed(1)}%</p>
          <p className="text-xs text-gray-400 mt-1">Return on investment</p>
        </div>

        <div className="bg-white/5 p-4 rounded-lg">
          <div className="flex items-center mb-2">
            <Sun className="h-5 w-5 text-orange-500 mr-2" />
            <span className="text-sm text-gray-300">Investment</span>
          </div>
          <p className="text-xl font-bold text-white">{formatCurrency(installationCost)}</p>
          <p className="text-xs text-gray-400 mt-1">Total system cost</p>
        </div>
      </div>

      <div className="mt-6 bg-gradient-to-r from-yellow-500/20 to-yellow-600/20 p-4 rounded-lg">
        <div className="flex items-center mb-2">
          <DollarSign className="h-5 w-5 text-yellow-500 mr-2" />
          <span className="text-sm text-white">EMI Calculator</span>
        </div>
        <div className="grid grid-cols-2 gap-4 mt-2">
          <div>
            <p className="text-xs text-gray-400">Monthly EMI (5 years)</p>
            <p className="text-lg font-bold text-white">{formatCurrency(monthlyEMI)}</p>
          </div>
          <div>
            <p className="text-xs text-gray-400">Monthly Savings</p>
            <p className="text-lg font-bold text-green-500">{formatCurrency(annualSavings / 12)}</p>
          </div>
        </div>
        <p className="text-xs text-gray-400 mt-2">
          *EMI calculated at 8.5% interest rate for 5 years
        </p>
      </div>

      <div className="mt-4 text-center">
        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          className="bg-gradient-to-r from-yellow-500 to-yellow-600 text-white px-6 py-2 rounded-full font-semibold"
        >
          Get Detailed Quote
        </motion.button>
      </div>
    </motion.div>
  );
}