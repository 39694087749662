import React from 'react';
import { motion } from 'framer-motion';
import { Sun, Cloud, Battery, Zap, ArrowDown, ArrowUp, TrendingUp, DollarSign } from 'lucide-react';

export default function ComparisonInsights({ monthlyBill, systemSize, annualSavings }) {
  const withoutSolarYearly = monthlyBill * 12;
  const withSolarYearly = withoutSolarYearly - annualSavings;
  const co2Reduction = systemSize * 1.5; // 1.5 tons per kW per year
  const treesEquivalent = co2Reduction * 45; // 45 trees per ton of CO2
  
  // Calculate 25-year projections with 5% annual electricity rate increase
  const twentyFiveYearWithout = Array(25).fill(0).reduce((acc, _, index) => {
    return acc + (withoutSolarYearly * Math.pow(1.05, index));
  }, 0);
  
  const twentyFiveYearWith = Array(25).fill(0).reduce((acc, _, index) => {
    return acc + (withSolarYearly * Math.pow(1.02, index)); // 2% maintenance cost increase
  }, 0);

  const insights = [
    {
      title: "Without Solar",
      icon: <Cloud className="text-red-500" />,
      items: [
        { 
          label: "Monthly Cost", 
          value: `₹${monthlyBill.toLocaleString()}`,
          subtext: "Current electricity bill" 
        },
        { 
          label: "Annual Cost", 
          value: `₹${withoutSolarYearly.toLocaleString()}`,
          subtext: "Yearly electricity expenses" 
        },
        { 
          label: "25 Year Cost", 
          value: `₹${Math.round(twentyFiveYearWithout).toLocaleString()}`,
          subtext: "With 5% annual increase" 
        },
        { 
          label: "Grid Dependency", 
          value: "100%",
          subtext: "Complete reliance on grid" 
        }
      ],
      color: "from-red-500/20 to-red-600/20"
    },
    {
      title: "With Solar",
      icon: <Sun className="text-green-500" />,
      items: [
        { 
          label: "Monthly Cost", 
          value: `₹${Math.round(withSolarYearly / 12).toLocaleString()}`,
          subtext: "After solar installation" 
        },
        { 
          label: "Annual Cost", 
          value: `₹${withSolarYearly.toLocaleString()}`,
          subtext: "Yearly expenses with solar" 
        },
        { 
          label: "25 Year Cost", 
          value: `₹${Math.round(twentyFiveYearWith).toLocaleString()}`,
          subtext: "Including maintenance" 
        },
        { 
          label: "Grid Dependency", 
          value: "20-30%",
          subtext: "Minimal grid reliance" 
        }
      ],
      color: "from-green-500/20 to-green-600/20"
    }
  ];

  const totalSavings = twentyFiveYearWithout - twentyFiveYearWith;
  const savingsPercentage = ((totalSavings / twentyFiveYearWithout) * 100).toFixed(1);

  return (
    <div className="space-y-6">
      <div className="grid md:grid-cols-2 gap-6">
        {insights.map((section, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.2 }}
            className={`relative overflow-hidden rounded-xl bg-gradient-to-br ${section.color} backdrop-blur-sm p-6 border border-white/10`}
          >
            <div className="flex items-center gap-3 mb-4">
              <div className="p-2 bg-white/10 rounded-lg">
                {section.icon}
              </div>
              <h3 className="text-xl font-bold text-white">{section.title}</h3>
            </div>

            <div className="space-y-4">
              {section.items.map((item, idx) => (
                <motion.div
                  key={idx}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: idx * 0.1 }}
                  className="bg-white/5 p-3 rounded-lg"
                >
                  <div className="flex justify-between items-center">
                    <span className="text-gray-300">{item.label}</span>
                    <span className="text-white font-semibold">{item.value}</span>
                  </div>
                  <p className="text-xs text-gray-400 mt-1">{item.subtext}</p>
                </motion.div>
              ))}
            </div>

            {index === 0 ? (
              <ArrowDown className="absolute bottom-2 right-2 text-red-500 animate-bounce" />
            ) : (
              <ArrowUp className="absolute bottom-2 right-2 text-green-500 animate-bounce" />
            )}
          </motion.div>
        ))}
      </div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4 }}
        className="bg-gradient-to-r from-yellow-500/20 to-yellow-600/20 rounded-xl p-6 border border-yellow-500/20"
      >
        <h4 className="text-lg font-semibold text-white mb-4">25 Year Financial Impact</h4>
        <div className="grid grid-cols-2 gap-4">
          <div className="bg-white/5 p-4 rounded-lg">
            <div className="flex items-center mb-2">
              <TrendingUp className="h-5 w-5 text-green-500 mr-2" />
              <span className="text-sm text-gray-300">Total Savings</span>
            </div>
            <p className="text-xl font-bold text-white">₹{Math.round(totalSavings).toLocaleString()}</p>
            <p className="text-xs text-gray-400 mt-1">{savingsPercentage}% lifetime savings</p>
          </div>
          <div className="bg-white/5 p-4 rounded-lg">
            <div className="flex items-center mb-2">
              <DollarSign className="h-5 w-5 text-yellow-500 mr-2" />
              <span className="text-sm text-gray-300">Monthly Impact</span>
            </div>
            <p className="text-xl font-bold text-white">₹{Math.round(totalSavings / (25 * 12)).toLocaleString()}</p>
            <p className="text-xs text-gray-400 mt-1">Average monthly savings</p>
          </div>
        </div>
      </motion.div>
    </div>
  );
}