import React from 'react';
import { motion } from 'framer-motion';
import { Leaf, CarFront, TreePine, Factory, Cloud, Zap, Globe, Home } from 'lucide-react';

export default function EnvironmentalImpact({ systemSize }) {
  // Environmental impact calculations
  const annualCO2Reduction = systemSize * 1.5; // 1.5 tons per kW per year
  const treesPlanted = annualCO2Reduction * 45; // 45 trees per ton of CO2
  const carEquivalent = annualCO2Reduction * 0.21; // 0.21 cars per ton of CO2
  const coalSaved = annualCO2Reduction * 0.4; // 0.4 tons of coal per ton of CO2
  const waterSaved = systemSize * 1000; // Liters of water saved in thermal power generation
  const homesPowered = systemSize * 0.3; // Average homes powered

  const impacts = [
    {
      icon: <Cloud className="h-8 w-8" />,
      title: "CO₂ Reduction",
      value: `${annualCO2Reduction.toFixed(1)} tons`,
      description: "Annual carbon dioxide emissions avoided",
      color: "from-blue-400 to-blue-600"
    },
    {
      icon: <TreePine className="h-8 w-8" />,
      title: "Trees Equivalent",
      value: `${Math.round(treesPlanted)} trees`,
      description: "Equal to planting this many trees",
      color: "from-green-400 to-green-600"
    },
    {
      icon: <CarFront className="h-8 w-8" />,
      title: "Cars Removed",
      value: `${carEquivalent.toFixed(1)} cars`,
      description: "Equivalent to removing cars from the road",
      color: "from-yellow-400 to-yellow-600"
    },
    {
      icon: <Factory className="h-8 w-8" />,
      title: "Coal Saved",
      value: `${coalSaved.toFixed(1)} tons`,
      description: "Amount of coal not burned",
      color: "from-purple-400 to-purple-600"
    }
  ];

  const additionalImpacts = [
    {
      icon: <Zap className="h-6 w-6" />,
      title: "Clean Energy Generated",
      value: `${(systemSize * 1500).toFixed(0)} kWh`,
      period: "Annual"
    },
    {
      icon: <Globe className="h-6 w-6" />,
      title: "Water Saved",
      value: `${waterSaved.toLocaleString()} L`,
      period: "Annual"
    },
    {
      icon: <Home className="h-6 w-6" />,
      title: "Homes Powered",
      value: homesPowered.toFixed(1),
      period: "Equivalent"
    }
  ];

  return (
    <div className="space-y-6">
      <div className="text-center mb-8">
        <div className="inline-flex items-center justify-center p-3 bg-green-500/20 rounded-full mb-4">
          <Leaf className="h-8 w-8 text-green-500" />
        </div>
        <h3 className="text-xl font-bold text-white">Environmental Impact</h3>
        <p className="text-gray-400 mt-2">Your contribution to a sustainable future</p>
      </div>

      <div className="grid grid-cols-2 gap-4">
        {impacts.map((impact, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            className="bg-white/10 backdrop-blur-sm rounded-xl p-6 border border-white/20 relative overflow-hidden group"
          >
            <div className={`w-12 h-12 rounded-lg bg-gradient-to-r ${impact.color} flex items-center justify-center mb-4 transform transition-all duration-300 group-hover:scale-110`}>
              {impact.icon}
            </div>
            
            <h4 className="text-lg font-semibold text-white mb-1">{impact.title}</h4>
            <p className="text-2xl font-bold text-white mb-2">{impact.value}</p>
            <p className="text-sm text-gray-400">{impact.description}</p>

            <div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-white/5 to-transparent rounded-full transform translate-x-16 -translate-y-16 group-hover:scale-150 transition-transform duration-500" />
          </motion.div>
        ))}
      </div>

      <div className="grid grid-cols-3 gap-4">
        {additionalImpacts.map((impact, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 + index * 0.1 }}
            className="bg-white/5 p-4 rounded-xl text-center"
          >
            <div className="inline-flex items-center justify-center p-2 bg-yellow-500/20 rounded-lg mb-3">
              {impact.icon}
            </div>
            <h5 className="text-sm text-gray-300">{impact.title}</h5>
            <p className="text-lg font-bold text-white mt-1">{impact.value}</p>
            <p className="text-xs text-gray-400">{impact.period}</p>
          </motion.div>
        ))}
      </div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.7 }}
        className="mt-8 p-6 bg-gradient-to-r from-green-500/10 to-green-600/10 rounded-xl border border-green-500/20"
      >
        <h4 className="text-lg font-semibold text-white mb-4">25 Year Environmental Impact</h4>
        <div className="grid grid-cols-3 gap-4">
          <div className="bg-white/5 p-4 rounded-lg">
            <p className="text-sm text-gray-400">Total CO₂ Reduction</p>
            <p className="text-xl font-bold text-white">{(annualCO2Reduction * 25).toFixed(1)} tons</p>
          </div>
          <div className="bg-white/5 p-4 rounded-lg">
            <p className="text-sm text-gray-400">Trees Equivalent</p>
            <p className="text-xl font-bold text-white">{Math.round(treesPlanted * 25)} trees</p>
          </div>
          <div className="bg-white/5 p-4 rounded-lg">
            <p className="text-sm text-gray-400">Coal Saved</p>
            <p className="text-xl font-bold text-white">{(coalSaved * 25).toFixed(1)} tons</p>
          </div>
        </div>
        <p className="text-center text-sm text-gray-400 mt-4">
          Your solar installation will continue to benefit the environment for generations to come
        </p>
      </motion.div>
    </div>
  );
}