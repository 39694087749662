export const historicalData = {
    electricityRates: {
      1970: 0.15,
      1980: 0.35,
      1990: 0.85,
      2000: 2.25,
      2010: 4.50,
      2020: 7.50,
      2024: 8.00
    },
    
    solarEfficiency: {
      1970: 6,
      1980: 8,
      1990: 12,
      2000: 15,
      2010: 18,
      2020: 20,
      2024: 21.5
    },
  
    installationCosts: {
      1970: 120000,
      1980: 100000,
      1990: 85000,
      2000: 75000,
      2010: 65000,
      2020: 55000,
      2024: 50000
    }
  };
  
  export const locationData = {
    'Bhopal': { solarHours: 5.5, efficiency: 1.0 },
    'Indore': { solarHours: 5.7, efficiency: 1.05 },
    'Gwalior': { solarHours: 5.3, efficiency: 0.95 },
    'Jabalpur': { solarHours: 5.4, efficiency: 0.98 }
  };
  
  export const propertyTypes = {
    residential: {
      subsidy: 0.4,
      baseLoad: 5,
      roofEfficiency: 1.0
    },
    commercial: {
      subsidy: 0.2,
      baseLoad: 15,
      roofEfficiency: 0.9
    },
    industrial: {
      subsidy: 0.15,
      baseLoad: 50,
      roofEfficiency: 0.85
    }
  };