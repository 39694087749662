import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { User, Phone, Mail, Home, MapPin, Sun, Calculator } from 'lucide-react';

export default function QuoteForm({ onClose, systemSize, monthlyBill, annualSavings }) {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    address: '',
    city: '',
    propertyType: 'residential',
    preferredTime: 'morning'
  });

  const [step, setStep] = useState(1);

  const handleChange = (e) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    onClose();
  };

  const formFields = {
    1: [
      {
        name: 'name',
        label: 'Full Name',
        type: 'text',
        icon: <User className="h-5 w-5" />,
        required: true
      },
      {
        name: 'phone',
        label: 'Phone Number',
        type: 'tel',
        icon: <Phone className="h-5 w-5" />,
        required: true
      },
      {
        name: 'email',
        label: 'Email Address',
        type: 'email',
        icon: <Mail className="h-5 w-5" />,
        required: true
      }
    ],
    2: [
      {
        name: 'address',
        label: 'Installation Address',
        type: 'text',
        icon: <Home className="h-5 w-5" />,
        required: true
      },
      {
        name: 'city',
        label: 'City',
        type: 'text',
        icon: <MapPin className="h-5 w-5" />,
        required: true
      }
    ]
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.9 }}
      className="bg-white rounded-xl p-8 max-w-md w-full m-4 relative"
    >
      <button
        onClick={onClose}
        className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
      >
        ✕
      </button>

      <div className="text-center mb-6">
        <div className="inline-flex items-center justify-center p-3 bg-yellow-500/20 rounded-full mb-4">
          <Calculator className="h-6 w-6 text-yellow-500" />
        </div>
        <h3 className="text-2xl font-bold text-gray-900">Get Your Free Quote</h3>
        <p className="text-gray-600 mt-2">
          System Size: {systemSize} kW | Monthly Savings: ₹{(annualSavings / 12).toLocaleString()}
        </p>
      </div>

      <div className="mb-6">
        <div className="flex justify-between items-center">
          {[1, 2].map((s) => (
            <div
              key={s}
              className={`flex-1 h-2 rounded-full mx-1 ${
                s <= step ? 'bg-yellow-500' : 'bg-gray-200'
              }`}
            />
          ))}
        </div>
        <div className="text-center mt-2 text-sm text-gray-600">
          Step {step} of 2
        </div>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        {formFields[step].map((field) => (
          <div key={field.name}>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {field.label}
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-gray-400">
                {field.icon}
              </div>
              <input
                type={field.type}
                name={field.name}
                value={formData[field.name]}
                onChange={handleChange}
                required={field.required}
                className="pl-10 w-full rounded-lg border-gray-300 focus:border-yellow-500 focus:ring-yellow-500"
              />
            </div>
          </div>
        ))}

        {step === 2 && (
          <>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Property Type
              </label>
              <select
                name="propertyType"
                value={formData.propertyType}
                onChange={handleChange}
                className="w-full rounded-lg border-gray-300 focus:border-yellow-500 focus:ring-yellow-500"
              >
                <option value="residential">Residential</option>
                <option value="commercial">Commercial</option>
                <option value="industrial">Industrial</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Preferred Contact Time
              </label>
              <select
                name="preferredTime"
                value={formData.preferredTime}
                onChange={handleChange}
                className="w-full rounded-lg border-gray-300 focus:border-yellow-500 focus:ring-yellow-500"
              >
                <option value="morning">Morning (9 AM - 12 PM)</option>
                <option value="afternoon">Afternoon (12 PM - 4 PM)</option>
                <option value="evening">Evening (4 PM - 7 PM)</option>
              </select>
            </div>
          </>
        )}

        <div className="flex space-x-4">
          {step > 1 && (
            <button
              type="button"
              onClick={() => setStep(step - 1)}
              className="flex-1 px-4 py-2 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 transition-colors"
            >
              Back
            </button>
          )}
          {step < 2 ? (
            <button
              type="button"
              onClick={() => setStep(step + 1)}
              className="flex-1 bg-yellow-500 text-white px-4 py-2 rounded-lg hover:bg-yellow-600 transition-colors"
            >
              Next
            </button>
          ) : (
            <button
              type="submit"
              className="flex-1 bg-yellow-500 text-white px-4 py-2 rounded-lg hover:bg-yellow-600 transition-colors"
            >
              Get Quote
            </button>
          )}
        </div>
      </form>
    </motion.div>
  );
}