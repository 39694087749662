import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { MapPin, Sun, Battery, ArrowRight, Users, Building2, Factory, Award, Shield, Star } from 'lucide-react';
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from '@react-spring/web';
import Projects3D from './Projects3D';

const projects = [
  {
    id: 1,
    type: "Residential",
    title: "Smart Home Solar Integration",
    location: "Bhopal, MP",
    image: "https://images.unsplash.com/photo-1513694203232-719a280e022f?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
    specs: {
      system: "10 kW",
      panels: "24 Premium",
      savings: "₹15,000/month",
      co2: "12 tons/year"
    },
    features: [
      "Smart home integration",
      "Battery backup",
      "Mobile monitoring",
      "Premium panels"
    ],
    testimonial: {
      name: "Rajesh Kumar",
      role: "Homeowner",
      quote: "Transformed our energy consumption completely",
      rating: 5,
      image: "https://randomuser.me/api/portraits/men/1.jpg"
    },
    gallery: [
      "https://images.unsplash.com/photo-1508514177221-188b1cf16e9d",
      "https://images.unsplash.com/photo-1509391366360-2e959784a276",
      "https://images.unsplash.com/photo-1497440001374-f26997328c1b"
    ],
    completion: "2024",
    roi: "3.5 years",
    color: "from-yellow-400 to-yellow-600",
    status: "Completed"
  },
  {
    id: 2,
    type: "Commercial",
    title: "Mall Rooftop Solar Plant",
    location: "Indore, MP",
    image: "https://images.unsplash.com/photo-1566093097221-ac2335b09e70?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
    specs: {
      system: "200 kW",
      panels: "480 Premium",
      savings: "₹2.5L/month",
      co2: "240 tons/year"
    },
    features: [
      "Zero export solution",
      "Peak load management",
      "Advanced monitoring",
      "Hybrid inverters"
    ],
    testimonial: {
      name: "Priya Sharma",
      role: "Mall Manager",
      quote: "Significant reduction in operational costs",
      rating: 5,
      image: "https://randomuser.me/api/portraits/women/1.jpg"
    },
    gallery: [
      "https://images.unsplash.com/photo-1566093097221-ac2335b09e70",
      "https://images.unsplash.com/photo-1559302995-f1d6d0cb6c39",
      "https://images.unsplash.com/photo-1555066931-bf19f8fd1085"
    ],
    completion: "2023",
    roi: "4 years",
    color: "from-blue-400 to-blue-600",
    status: "In Progress"
  },
  {
    id: 3,
    type: "Industrial",
    title: "Manufacturing Plant Solar",
    location: "Gwalior, MP",
    image: "https://images.unsplash.com/photo-1605980776566-0486c3ac7617?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
    specs: {
      system: "500 kW",
      panels: "1200 Premium",
      savings: "₹6L/month",
      co2: "600 tons/year"
    },
    features: [
      "Load optimization",
      "Power quality control",
      "24/7 monitoring",
      "Industrial grade"
    ],
    testimonial: {
      name: "Amit Patel",
      role: "Factory Owner",
      quote: "Best decision for our manufacturing unit",
      rating: 5,
      image: "https://randomuser.me/api/portraits/men/2.jpg"
    },
    gallery: [
      "https://images.unsplash.com/photo-1605980776566-0486c3ac7617",
      "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e",
      "https://images.unsplash.com/photo-1581094794329-c8112a89af12"
    ],
    completion: "2024",
    roi: "3.8 years",
    color: "from-green-400 to-green-600",
    status: "Completed"
  }
];

const certifications = [
  {
    id: "iso9001",
    name: "ISO 9001:2015",
    description: "Quality Management System",
    issuer: "International Organization for Standardization",
    validUntil: "2025",
    icon: <Shield className="h-8 w-8" />,
    color: "yellow"
  },
  {
    id: "mnre",
    name: "MNRE Approved",
    description: "Ministry of New and Renewable Energy",
    issuer: "Government of India",
    validUntil: "2024",
    icon: <Sun className="h-8 w-8" />,
    color: "blue"
  },
  {
    id: "cea",
    name: "CEA Certified",
    description: "Central Electricity Authority",
    issuer: "Government of India",
    validUntil: "2024",
    icon: <Battery className="h-8 w-8" />,
    color: "green"
  }
];

export default function Projects() {
  const [selectedProject, setSelectedProject] = useState(null);
  const [activeFilter, setActiveFilter] = useState('all');
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true
  });

  const filterProjects = (type) => {
    setActiveFilter(type);
    setSelectedProject(null);
  };

  const filteredProjects = activeFilter === 'all' 
    ? projects 
    : projects.filter(project => project.type.toLowerCase() === activeFilter.toLowerCase());

  const nextImage = () => {
    if (selectedProject) {
      setCurrentImageIndex((prev) => 
        (prev + 1) % selectedProject.gallery.length
      );
    }
  };

  const prevImage = () => {
    if (selectedProject) {
      setCurrentImageIndex((prev) => 
        prev === 0 ? selectedProject.gallery.length - 1 : prev - 1
      );
    }
  };

  useEffect(() => {
    setCurrentImageIndex(0);
  }, [selectedProject]);

  return (
    <>
      <section className="py-24 bg-gradient-to-br from-gray-900 to-gray-800 relative overflow-hidden" id="certifications">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-[url('/grid.svg')] bg-center [mask-image:linear-gradient(180deg,white,rgba(255,255,255,0))]" />
          <div className="absolute inset-0 bg-gradient-to-br from-yellow-500/20 via-transparent to-transparent" />
        </div>

        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center"
          >
            <span className="text-yellow-500 font-semibold tracking-wider uppercase">Certifications</span>
            <h2 className="mt-2 text-3xl font-bold text-white sm:text-4xl lg:text-5xl">
              Our Accreditations
            </h2>
            <p className="mt-4 text-xl text-gray-300 max-w-2xl mx-auto">
              Recognized and certified by leading industry authorities
            </p>
          </motion.div>

          <div className="mt-16 grid gap-8 md:grid-cols-3">
            {certifications.map((cert, index) => (
              <motion.div
                key={cert.id}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.2 }}
                className="bg-white/10 backdrop-blur-sm rounded-xl p-6 border border-white/20"
              >
                <div className={`w-16 h-16 rounded-xl bg-gradient-to-r from-${cert.color}-400 to-${cert.color}-600 flex items-center justify-center mb-6`}>
                  {cert.icon}
                </div>
                <h3 className="text-xl font-bold text-white mb-2">{cert.name}</h3>
                <p className="text-gray-300 mb-4">{cert.description}</p>
                <div className="text-sm text-gray-400">
                  <p>Issuer: {cert.issuer}</p>
                  <p>Valid until: {cert.validUntil}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-24 bg-gray-50 relative overflow-hidden" id="projects">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center"
          >
            <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl">
              Our Featured Projects
            </h2>
            <p className="mt-4 text-xl text-gray-600">
              Discover how we're transforming energy consumption across Madhya Pradesh
            </p>
          </motion.div>

          <div className="mt-8 flex justify-center space-x-4">
            {['all', 'residential', 'commercial', 'industrial'].map((type) => (
              <button
                key={type}
                onClick={() => filterProjects(type)}
                className={`px-6 py-2 rounded-full transition-all ${
                  activeFilter === type
                    ? 'bg-yellow-500 text-white'
                    : 'text-gray-600 hover:text-yellow-500'
                }`}
              >
                {type.charAt(0).toUpperCase() + type.slice(1)}
              </button>
            ))}
          </div>

          <Projects3D />

          <div ref={ref} className="mt-16 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
            {filteredProjects.map((project, index) => (
              <motion.div
                key={project.id}
                initial={{ opacity: 0, y: 20 }}
                animate={inView ? { opacity: 1, y: 0 } : {}}
                transition={{ delay: index * 0.1 }}
                whileHover={{ y: -10 }}
                className="group relative"
              >
                <div className="relative overflow-hidden rounded-2xl shadow-lg bg-white">
                  <div className="relative h-64 overflow-hidden">
                    <motion.img
                      src={project.image}
                      alt={project.title}
                      className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
                    />
                    <div className={`absolute inset-0 bg-gradient-to-t ${project.color} mix-blend-multiply opacity-60`} />
                    
                    <div className="absolute bottom-0 p-6 w-full">
                      <h3 className="text-xl font-semibold text-white">{project.title}</h3>
                      <div className="flex items-center mt-2 text-white/80">
                        <MapPin className="h-4 w-4 mr-1" />
                        <span className="text-sm">{project.location}</span>
                      </div>
                    </div>
                  </div>

                  <div className="p-6">
                    <div className="flex justify-between items-center mb-4">
                      <div>
                        <p className="text-yellow-500 font-semibold">{project.specs.system}</p>
                        <p className="text-sm text-gray-600">{project.specs.savings}</p>
                      </div>
                      <motion.button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={() => setSelectedProject(project)}
                        className={`p-2 rounded-full bg-gradient-to-r ${project.color} text-white`}
                      >
                        <ArrowRight className="h-5 w-5" />
                      </motion.button>
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                      {Object.entries(project.specs).slice(0, 4).map(([key, value], i) => (
                        <motion.div
                          key={i}
                          initial={{ opacity: 0, y: 10 }}
                          animate={inView ? { opacity: 1, y: 0 } : {}}
                          transition={{ delay: index * 0.1 + i * 0.1 }}
                          className="p-2 rounded-lg bg-gray-50 hover:bg-gray-100 transition-colors"
                        >
                          <p className="text-xs text-gray-600 capitalize">{key}</p>
                          <p className="text-sm font-semibold text-gray-900">{value}</p>
                        </motion.div>
                      ))}
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>

        <AnimatePresence>
          {selectedProject && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4"
              onClick={() => setSelectedProject(null)}
            >
              <motion.div
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                className="bg-white rounded-2xl max-w-4xl w-full overflow-hidden"
                onClick={e => e.stopPropagation()}
              >
                <div className="relative">
                  <div className="relative h-96">
                    <motion.img
                      key={currentImageIndex}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      src={selectedProject.gallery[currentImageIndex]}
                      alt={`Project image ${currentImageIndex + 1}`}
                      className="w-full h-full object-cover"
                    />
                    <div className={`absolute inset-0 bg-gradient-to-t ${selectedProject.color} mix-blend-multiply opacity-60`} />
                    
                    <button
                      onClick={() => prevImage()}
                      className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-white/20 p-2 rounded-full backdrop-blur-sm hover:bg-white/40 transition-colors"
                    >
                      <ArrowRight className="h-6 w-6 text-white transform rotate-180" />
                    </button>
                    
                    <button
                      onClick={() => nextImage()}
                      className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-white/20 p-2 rounded-full backdrop-blur-sm hover:bg-white/40 transition-colors"
                    >
                      <ArrowRight className="h-6 w-6 text-white" />
                    </button>

                    <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
                      {selectedProject.gallery.map((_, index) => (
                        <button
                          key={index}
                          onClick={() => setCurrentImageIndex(index)}
                          className={`w-2 h-2 rounded-full transition-colors ${
                            currentImageIndex === index ? 'bg-white' : 'bg-white/50'
                          }`}
                        />
                      ))}
                    </div>
                  </div>

                  <button
                    onClick={() => setSelectedProject(null)}
                    className="absolute top-4 right-4 bg-white/20 p-2 rounded-full backdrop-blur-sm hover:bg-white/40 transition-colors"
                  >
                    <span className="text-white">✕</span>
                  </button>
                </div>

                <div className="p-8">
                  <div className="flex items-center justify-between mb-6">
                    <div>
                      <h3 className="text-2xl font-bold text-gray-900">{selectedProject.title}</h3>
                      <div className="flex items-center mt-2 text-gray-600">
                        <MapPin className="h-5 w-5 mr-2" />
                        <span>{selectedProject.location}</span>
                      </div>
                    </div>
                    <div className="flex items-center space-x-2">
                      <span className={`px-3 py-1 rounded-full text-sm ${
                        selectedProject.status === 'Completed' 
                          ? 'bg-green-100 text-green-800'
                          : 'bg-yellow-100 text-yellow-800'
                      }`}>
                        {selectedProject.status}
                      </span>
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-8">
                    <div>
                      <h4 className="text-lg font-semibold mb-4">System Details</h4>
                      <div className="space-y-4">
                        {Object.entries(selectedProject.specs).map(([key, value], i) => (
                          <div key={i} className="flex items-center justify-between bg-gray-50 p-3 rounded-lg">
                            <span className="text-gray-600 capitalize">{key}</span>
                            <span className="font-semibold">{value}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold mb-4">Key Features</h4>
                      <div className="space-y-3">
                        {selectedProject.features.map((feature, i) => (
                          <div key={i} className="flex items-center">
                            <Shield className="h-5 w-5 text-yellow-500 mr-2" />
                            <span>{feature}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="mt-8 p-6 bg-gray-50 rounded-xl">
                    <div className="flex items-center space-x-4">
                      <img
                        src={selectedProject.testimonial.image}
                        alt={selectedProject.testimonial.name}
                        className="w-16 h-16 rounded-full"
                      />
                      <div>
                        <div className="flex items-center">
                          <p className="font-semibold">{selectedProject.testimonial.name}</p>
                          <span className="mx-2">•</span>
                          <p className="text-gray-600">{selectedProject.testimonial.role}</p>
                        </div>
                        <div className="flex items-center mt-1">
                          {[...Array(selectedProject.testimonial.rating)].map((_, i) => (
                            <Star key={i} className="h-4 w-4 text-yellow-500 fill-yellow-500" />
                          ))}
                        </div>
                        <p className="mt-2 italic text-gray-700">"{selectedProject.testimonial.quote}"</p>
                      </div>
                    </div>
                  </div>

                  <div className="mt-8 flex justify-between items-center">
                    <div className="space-y-2">
                      <p className="text-sm text-gray-600">Project Completion</p>
                      <p className="font-semibold">{selectedProject.completion}</p>
                    </div>
                    <div className="space-y-2">
                      <p className="text-sm text-gray-600">Return on Investment</p>
                      <p className="font-semibold">{selectedProject.roi}</p>
                    </div>
                    <motion.button
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      className={`bg-gradient-to-r ${selectedProject.color} text-white px-8 py-3 rounded-xl font-semibold`}
                    >
                      Get Similar Solution
                    </motion.button>
                  </div>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </section>
    </>
  );
}