import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { TrendingUp, ArrowUp, Sun, Cloud, Calendar } from 'lucide-react';

export default function InsightsGraph({ monthlyBill, yearlySavings }) {
  const [selectedMonth, setSelectedMonth] = useState(null);
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  
  // Enhanced seasonal factors with weather impact
  const seasonalFactors = {
    winter: { months: [0, 1, 11], factor: 0.8, weather: 'Shorter days, moderate sunlight' },
    spring: { months: [2, 3, 4], factor: 1.1, weather: 'Clear skies, optimal conditions' },
    summer: { months: [5, 6, 7], factor: 1.2, weather: 'Peak sunlight hours' },
    autumn: { months: [8, 9, 10], factor: 0.9, weather: 'Variable conditions' }
  };

  const getSeasonalFactor = (monthIndex) => {
    const season = Object.values(seasonalFactors).find(({ months }) => 
      months.includes(monthIndex)
    );
    return season;
  };

  const withoutSolar = Array(12).fill(monthlyBill);
  const withSolar = withoutSolar.map((bill, index) => {
    const season = getSeasonalFactor(index);
    const seasonalSavings = (yearlySavings / 12) * season.factor;
    return Math.max(0, bill - seasonalSavings);
  });

  const maxValue = Math.max(...withoutSolar);
  const getHeight = (value) => (value / maxValue) * 100;

  const getSeasonColor = (monthIndex) => {
    const colors = {
      winter: 'from-blue-400 to-blue-600',
      spring: 'from-green-400 to-green-600',
      summer: 'from-yellow-400 to-yellow-600',
      autumn: 'from-orange-400 to-orange-600'
    };

    const season = Object.entries(seasonalFactors).find(([_, { months }]) => 
      months.includes(monthIndex)
    );

    return colors[season[0]];
  };

  return (
    <div className="bg-white/10 backdrop-blur-sm rounded-xl p-6 border border-white/20">
      <div className="flex items-center justify-between mb-6">
        <div>
          <h3 className="text-xl font-bold text-white">Annual Cost Comparison</h3>
          <p className="text-sm text-gray-400">Monthly breakdown with seasonal variations</p>
        </div>
        <div className="flex items-center space-x-4">
          <div className="flex items-center">
            <div className="w-3 h-3 bg-red-500 rounded-full mr-2"></div>
            <span className="text-sm text-gray-400">Without Solar</span>
          </div>
          <div className="flex items-center">
            <div className="w-3 h-3 bg-green-500 rounded-full mr-2"></div>
            <span className="text-sm text-gray-400">With Solar</span>
          </div>
        </div>
      </div>

      <div className="relative h-64">
        <div className="absolute inset-0 flex items-end justify-between">
          {months.map((month, index) => (
            <div 
              key={month} 
              className="flex-1 flex flex-col items-center"
              onMouseEnter={() => setSelectedMonth(index)}
              onMouseLeave={() => setSelectedMonth(null)}
            >
              <motion.div
                initial={{ height: 0 }}
                animate={{ height: `${getHeight(withoutSolar[index])}%` }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className={`w-4 bg-gradient-to-t from-red-500/50 to-red-400/50 rounded-t-sm relative group cursor-pointer ${
                  selectedMonth === index ? 'ring-2 ring-red-500 ring-offset-2 ring-offset-gray-900' : ''
                }`}
              >
                <div className={`absolute -top-6 left-1/2 transform -translate-x-1/2 bg-red-500 text-white text-xs px-2 py-1 rounded whitespace-nowrap z-10 transition-opacity ${
                  selectedMonth === index ? 'opacity-100' : 'opacity-0'
                }`}>
                  ₹{withoutSolar[index].toLocaleString()}
                </div>
              </motion.div>
              <motion.div
                initial={{ height: 0 }}
                animate={{ height: `${getHeight(withSolar[index])}%` }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className={`w-4 bg-gradient-to-t from-green-500/50 to-green-400/50 rounded-t-sm -mt-1 relative group cursor-pointer ${
                  selectedMonth === index ? 'ring-2 ring-green-500 ring-offset-2 ring-offset-gray-900' : ''
                }`}
                style={{ marginBottom: '20px' }}
              >
                <div className={`absolute -top-6 left-1/2 transform -translate-x-1/2 bg-green-500 text-white text-xs px-2 py-1 rounded whitespace-nowrap z-10 transition-opacity ${
                  selectedMonth === index ? 'opacity-100' : 'opacity-0'
                }`}>
                  ₹{withSolar[index].toLocaleString()}
                </div>
              </motion.div>
              <span className="text-xs text-gray-400 absolute bottom-0">{month}</span>
            </div>
          ))}
        </div>

        {/* Grid lines */}
        <div className="absolute inset-0 flex flex-col justify-between pointer-events-none">
          {[0, 1, 2, 3, 4].map((_, index) => (
            <div
              key={index}
              className="w-full h-px bg-white/10"
              style={{ bottom: `${(index / 4) * 100}%` }}
            >
              <span className="absolute right-full pr-2 text-xs text-gray-400">
                ₹{Math.round((maxValue * (index / 4))).toLocaleString()}
              </span>
            </div>
          ))}
        </div>
      </div>

      {/* Seasonal Insights */}
      {selectedMonth !== null && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          className={`mt-4 p-4 rounded-lg bg-gradient-to-r ${getSeasonColor(selectedMonth)} bg-opacity-10`}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <Calendar className="h-5 w-5 text-white" />
              <span className="text-white font-semibold">{months[selectedMonth]} Insights</span>
            </div>
            <div className="flex items-center space-x-2">
              <Sun className="h-5 w-5 text-yellow-500" />
              <span className="text-gray-300">{getSeasonalFactor(selectedMonth).weather}</span>
            </div>
          </div>
          <div className="mt-2 grid grid-cols-2 gap-4">
            <div className="text-sm">
              <span className="text-gray-400">Efficiency Factor:</span>
              <span className="ml-2 text-white">{(getSeasonalFactor(selectedMonth).factor * 100).toFixed(0)}%</span>
            </div>
            <div className="text-sm">
              <span className="text-gray-400">Monthly Savings:</span>
              <span className="ml-2 text-green-400">
                ₹{(monthlyBill - withSolar[selectedMonth]).toLocaleString()}
              </span>
            </div>
          </div>
        </motion.div>
      )}

      <div className="mt-6 grid grid-cols-2 gap-4">
        <div className="bg-white/5 p-4 rounded-lg">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Cloud className="h-5 w-5 text-red-500 mr-2" />
              <span className="text-sm text-gray-300">Without Solar</span>
            </div>
            <span className="text-white font-bold">₹{monthlyBill.toLocaleString()}/mo</span>
          </div>
          <div className="mt-2 text-xs text-gray-400">
            Annual Cost: ₹{(monthlyBill * 12).toLocaleString()}
          </div>
        </div>
        <div className="bg-white/5 p-4 rounded-lg">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Sun className="h-5 w-5 text-green-500 mr-2" />
              <span className="text-sm text-gray-300">With Solar</span>
            </div>
            <span className="text-white font-bold">
              ₹{Math.max(0, monthlyBill - (yearlySavings / 12)).toLocaleString()}/mo
            </span>
          </div>
          <div className="mt-2 text-xs text-gray-400">
            Annual Cost: ₹{Math.max(0, (monthlyBill * 12) - yearlySavings).toLocaleString()}
          </div>
        </div>
      </div>

      <div className="mt-4 p-3 bg-green-500/10 rounded-lg">
        <div className="flex items-center justify-between">
          <span className="text-sm text-gray-300">Total Annual Savings</span>
          <span className="text-lg font-bold text-green-500">₹{yearlySavings.toLocaleString()}</span>
        </div>
        <div className="mt-1 text-xs text-gray-400">
          {((yearlySavings / (monthlyBill * 12)) * 100).toFixed(1)}% reduction in electricity costs
        </div>
      </div>
    </div>
  );
}