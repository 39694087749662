import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { TrendingUp, ArrowUpRight, ArrowDownRight } from 'lucide-react';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export default function LiveChart({ baseValue = 5000 }) {
  const [data, setData] = useState([]);
  const [trend, setTrend] = useState({ value: 0, isPositive: true });

  // Generate initial data points
  useEffect(() => {
    const initialData = Array.from({ length: 20 }, () => baseValue);
    setData(initialData);
  }, [baseValue]);

  // Update data every second
  useEffect(() => {
    const interval = setInterval(() => {
      setData(prevData => {
        const variation = (Math.random() - 0.5) * (baseValue * 0.1);
        const newValue = prevData[prevData.length - 1] + variation;
        const newData = [...prevData.slice(1), newValue];
        
        // Calculate trend
        const averageBefore = prevData.slice(-5).reduce((a, b) => a + b, 0) / 5;
        const averageAfter = newData.slice(-5).reduce((a, b) => a + b, 0) / 5;
        const trendValue = ((averageAfter - averageBefore) / averageBefore) * 100;
        
        setTrend({
          value: Math.abs(trendValue),
          isPositive: trendValue >= 0
        });

        return newData;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [baseValue]);

  const chartData = {
    labels: Array.from({ length: data.length }, (_, i) => ''),
    datasets: [
      {
        label: 'Power Generation',
        data: data,
        fill: true,
        borderColor: '#FDB813',
        backgroundColor: 'rgba(253, 184, 19, 0.1)',
        tension: 0.4,
        pointRadius: 0,
        borderWidth: 2,
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          label: function(context) {
            return `${context.parsed.y.toFixed(2)} W`;
          }
        }
      }
    },
    scales: {
      x: {
        display: false
      },
      y: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        ticks: {
          color: 'rgba(255, 255, 255, 0.7)',
          callback: function(value) {
            return `${value.toFixed(0)} W`;
          }
        }
      }
    },
    interaction: {
      intersect: false,
      mode: 'index'
    },
    animation: {
      duration: 0
    }
  };

  return (
    <div className="bg-white/10 backdrop-blur-sm rounded-xl p-6 border border-white/20">
      <div className="flex items-center justify-between mb-6">
        <div>
          <h3 className="text-xl font-bold text-white">Live Power Generation</h3>
          <p className="text-sm text-gray-400">Real-time system output</p>
        </div>
        <div className={`flex items-center px-3 py-1 rounded-full ${
          trend.isPositive ? 'bg-green-500/20 text-green-500' : 'bg-red-500/20 text-red-500'
        }`}>
          {trend.isPositive ? (
            <ArrowUpRight className="h-4 w-4 mr-1" />
          ) : (
            <ArrowDownRight className="h-4 w-4 mr-1" />
          )}
          <span className="text-sm font-semibold">{trend.value.toFixed(2)}%</span>
        </div>
      </div>

      <div className="h-64">
        <Line data={chartData} options={options} />
      </div>

      <div className="mt-6 grid grid-cols-3 gap-4">
        <div className="bg-white/5 p-4 rounded-lg">
          <p className="text-sm text-gray-400">Current Output</p>
          <p className="text-xl font-bold text-white">
            {data[data.length - 1]?.toFixed(2)} W
          </p>
        </div>
        <div className="bg-white/5 p-4 rounded-lg">
          <p className="text-sm text-gray-400">Average</p>
          <p className="text-xl font-bold text-white">
            {(data.reduce((a, b) => a + b, 0) / data.length).toFixed(2)} W
          </p>
        </div>
        <div className="bg-white/5 p-4 rounded-lg">
          <p className="text-sm text-gray-400">Peak</p>
          <p className="text-xl font-bold text-white">
            {Math.max(...data).toFixed(2)} W
          </p>
        </div>
      </div>
    </div>
  );
}