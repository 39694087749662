import React from 'react';
import { motion } from 'framer-motion';
import { DollarSign, TrendingUp, Calendar, Wallet } from 'lucide-react';

export default function SavingsBreakdown({ monthlyBill, systemSize, annualSavings }) {
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 0
    }).format(amount);
  };

  const breakdowns = [
    {
      period: "Monthly",
      savings: annualSavings / 12,
      comparison: monthlyBill,
      icon: <Wallet className="h-6 w-6" />,
      color: "from-green-400 to-green-600"
    },
    {
      period: "Yearly",
      savings: annualSavings,
      comparison: monthlyBill * 12,
      icon: <Calendar className="h-6 w-6" />,
      color: "from-blue-400 to-blue-600"
    },
    {
      period: "10 Years",
      savings: annualSavings * 10,
      comparison: monthlyBill * 12 * 10,
      icon: <TrendingUp className="h-6 w-6" />,
      color: "from-purple-400 to-purple-600"
    },
    {
      period: "25 Years",
      savings: annualSavings * 25,
      comparison: monthlyBill * 12 * 25,
      icon: <DollarSign className="h-6 w-6" />,
      color: "from-yellow-400 to-yellow-600"
    }
  ];

  return (
    <div className="mt-8">
      <h3 className="text-xl font-bold text-white mb-6">Savings Breakdown</h3>
      
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
        {breakdowns.map((breakdown, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            className="bg-white/10 backdrop-blur-sm rounded-xl p-4 border border-white/20"
          >
            <div className={`w-10 h-10 rounded-lg bg-gradient-to-r ${breakdown.color} flex items-center justify-center mb-3`}>
              {breakdown.icon}
            </div>
            
            <div className="space-y-2">
              <p className="text-sm text-gray-400">{breakdown.period}</p>
              <p className="text-lg font-bold text-white">
                {formatCurrency(breakdown.savings)}
              </p>
              <div className="text-xs text-gray-400">
                vs {formatCurrency(breakdown.comparison)} without solar
              </div>
              <div className="text-sm text-green-500">
                {((breakdown.savings / breakdown.comparison) * 100).toFixed(1)}% savings
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
}