import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Calculator as CalcIcon, Sun, Battery, DollarSign, Zap, ArrowRight } from 'lucide-react';
import InsightsGraph from './calculator/InsightsGraph';
import ROICard from './calculator/ROICard';
import ComparisonInsights from './calculator/ComparisonInsights';
import EnvironmentalImpact from './calculator/EnvironmentalImpact';
import SavingsBreakdown from './calculator/SavingsBreakdown';
import QuoteForm from './calculator/QuoteForm';
import LiveChart from './calculator/LiveChart';
import { historicalData, locationData, propertyTypes } from '../utils/calculatorData';

export default function Calculator() {
  const [formData, setFormData] = useState({
    monthlyBill: 5000,
    roofArea: 500,
    location: 'Bhopal',
    propertyType: 'residential',
    solarHours: 5.5,
    unitPrice: historicalData.electricityRates[1970]
  });

  const [results, setResults] = useState({
    systemSize: 0,
    annualSavings: 0,
    co2Reduction: 0,
    installationCost: 0,
    paybackPeriod: 0,
    governmentSubsidy: 0,
    dailyGeneration: 0
  });

  const [activeTab, setActiveTab] = useState('calculator');
  const [showQuoteForm, setShowQuoteForm] = useState(false);

  useEffect(() => {
    calculateSavings();
  }, [formData]);

  const calculateSavings = () => {
    const { monthlyBill, roofArea, propertyType, location } = formData;
    
    const systemSize = (roofArea * 0.01) * propertyTypes[propertyType].roofEfficiency;
    const locationFactor = locationData[location];
    const dailyGeneration = systemSize * locationFactor.solarHours * locationFactor.efficiency;
    const annualGeneration = dailyGeneration * 365;
    const annualSavings = annualGeneration * historicalData.electricityRates[1970];
    const co2Reduction = annualGeneration * 0.85 / 1000;
    const baseInstallationCost = systemSize * historicalData.installationCosts[1970];
    const subsidyPercentage = propertyTypes[propertyType].subsidy;
    const governmentSubsidy = baseInstallationCost * subsidyPercentage;
    const finalInstallationCost = baseInstallationCost - governmentSubsidy;
    const paybackPeriod = finalInstallationCost / annualSavings;

    setResults({
      systemSize: systemSize.toFixed(2),
      annualSavings: annualSavings.toFixed(0),
      co2Reduction: co2Reduction.toFixed(2),
      installationCost: finalInstallationCost.toFixed(0),
      paybackPeriod: paybackPeriod.toFixed(1),
      governmentSubsidy: governmentSubsidy.toFixed(0),
      dailyGeneration: dailyGeneration.toFixed(1)
    });
  };

  const tabs = [
    { id: 'calculator', label: 'Calculator', icon: <CalcIcon className="h-4 w-4" /> },
    { id: 'insights', label: 'Insights', icon: <Zap className="h-4 w-4" /> },
    { id: 'comparison', label: 'Comparison', icon: <DollarSign className="h-4 w-4" /> },
    { id: 'environmental', label: 'Environmental Impact', icon: <Sun className="h-4 w-4" /> }
  ];

  return (
    <section id="calculator" className="py-24 bg-gradient-to-br from-gray-900 to-gray-800 relative overflow-hidden">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[url('/grid.svg')] bg-center [mask-image:linear-gradient(180deg,white,rgba(255,255,255,0))]" />
        <div className="absolute inset-0 bg-gradient-to-br from-yellow-500/20 via-transparent to-transparent" />
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center"
        >
          <h2 className="text-3xl font-bold text-white sm:text-4xl">
            Solar Savings Calculator
          </h2>
          <p className="mt-4 text-xl text-gray-300">
            Calculate your potential savings and environmental impact
          </p>
        </motion.div>

        <div className="mt-12">
          <div className="flex space-x-4 mb-8 overflow-x-auto">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`px-6 py-2 rounded-full transition-all flex items-center space-x-2 ${
                  activeTab === tab.id
                    ? 'bg-yellow-500 text-white'
                    : 'text-gray-400 hover:text-white'
                }`}
              >
                {tab.icon}
                <span>{tab.label}</span>
              </button>
            ))}
          </div>

          <div className="bg-white/10 backdrop-blur-sm rounded-2xl p-8">
            {activeTab === 'calculator' && (
              <div className="grid lg:grid-cols-2 gap-8">
                <div className="space-y-6">
                  {[
                    {
                      label: "Monthly Electricity Bill (₹)",
                      name: "monthlyBill",
                      type: "range",
                      min: 1000,
                      max: 50000,
                      step: 500
                    },
                    {
                      label: "Available Roof Area (sq ft)",
                      name: "roofArea",
                      type: "range",
                      min: 100,
                      max: 10000,
                      step: 100
                    }
                  ].map((field) => (
                    <div key={field.name} className="space-y-2">
                      <div className="flex justify-between">
                        <label className="text-white">{field.label}</label>
                        <span className="text-yellow-500">
                          {field.name === "monthlyBill" 
                            ? `₹${formData[field.name].toLocaleString()}`
                            : formData[field.name]}
                        </span>
                      </div>
                      <input
                        type={field.type}
                        min={field.min}
                        max={field.max}
                        step={field.step}
                        value={formData[field.name]}
                        onChange={(e) => setFormData(prev => ({
                          ...prev,
                          [field.name]: parseFloat(e.target.value)
                        }))}
                        className="w-full"
                      />
                    </div>
                  ))}

                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-white mb-2">Location</label>
                      <select
                        value={formData.location}
                        onChange={(e) => setFormData(prev => ({
                          ...prev,
                          location: e.target.value,
                          solarHours: locationData[e.target.value].solarHours
                        }))}
                        className="w-full bg-white/10 border border-white/20 rounded-lg p-2 text-white"
                      >
                        {Object.keys(locationData).map((city) => (
                          <option key={city} value={city}>{city}</option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <label className="block text-white mb-2">Property Type</label>
                      <select
                        value={formData.propertyType}
                        onChange={(e) => setFormData(prev => ({
                          ...prev,
                          propertyType: e.target.value
                        }))}
                        className="w-full bg-white/10 border border-white/20 rounded-lg p-2 text-white"
                      >
                        {Object.keys(propertyTypes).map((type) => (
                          <option key={type} value={type}>
                            {type.charAt(0).toUpperCase() + type.slice(1)}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <LiveChart baseValue={parseFloat(results.dailyGeneration) * 1000} />
                </div>

                <ROICard
                  installationCost={parseFloat(results.installationCost)}
                  annualSavings={parseFloat(results.annualSavings)}
                  paybackPeriod={parseFloat(results.paybackPeriod)}
                  onGetQuote={() => setShowQuoteForm(true)}
                />
              </div>
            )}

            {activeTab === 'insights' && (
              <div>
                <InsightsGraph
                  monthlyBill={formData.monthlyBill}
                  yearlySavings={parseFloat(results.annualSavings)}
                />
                <SavingsBreakdown
                  monthlyBill={formData.monthlyBill}
                  systemSize={parseFloat(results.systemSize)}
                  annualSavings={parseFloat(results.annualSavings)}
                />
              </div>
            )}

            {activeTab === 'comparison' && (
              <ComparisonInsights
                monthlyBill={formData.monthlyBill}
                systemSize={parseFloat(results.systemSize)}
                annualSavings={parseFloat(results.annualSavings)}
              />
            )}

            {activeTab === 'environmental' && (
              <EnvironmentalImpact
                systemSize={parseFloat(results.systemSize)}
              />
            )}
          </div>
        </div>
      </div>

      <AnimatePresence>
        {showQuoteForm && (
          <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
            <QuoteForm
              onClose={() => setShowQuoteForm(false)}
              systemSize={parseFloat(results.systemSize)}
              monthlyBill={formData.monthlyBill}
              annualSavings={parseFloat(results.annualSavings)}
            />
          </div>
        )}
      </AnimatePresence>
    </section>
  );
}