// Navigation configuration
export const navigationConfig = {
    mainSections: [
      { id: 'home', label: 'Home' },
      { id: 'features', label: 'Features' },
      { id: 'solutions', label: 'Solutions' },
      { id: 'products', label: 'Products' },
      { id: 'projects', label: 'Projects' },
      { id: 'testimonials', label: 'Testimonials' },
      { id: 'about', label: 'About' },
      { id: 'contact', label: 'Contact' }
    ],
    
    services: [
      { id: 'residential', label: 'Residential Solar', section: 'solutions' },
      { id: 'commercial', label: 'Commercial Solar', section: 'solutions' },
      { id: 'industrial', label: 'Industrial Solar', section: 'solutions' },
      { id: 'maintenance', label: 'Maintenance', section: 'solutions' }
    ],
  
    socialLinks: [
      { id: 'facebook', url: 'https://facebook.com', label: 'Facebook' },
      { id: 'twitter', url: 'https://twitter.com', label: 'Twitter' },
      { id: 'instagram', url: 'https://instagram.com', label: 'Instagram' },
      { id: 'linkedin', url: 'https://linkedin.com', label: 'LinkedIn' }
    ],
  
    contact: {
      phone: '+91 755-123-4567',
      email: 'info@nathunenergies.com',
      address: '123 MP Nagar, Zone 1, Bhopal, Madhya Pradesh 462011'
    }
  };